.contact {
    display: flex;
    overflow-y: auto; 
    position: absolute;
    left: 0; right: 0; 
    top: 80px; bottom: 0;
    background: #000;
    color: #FFF;
    padding: 0 25px;
    font-family: 'Microsoft Sans Serif';
}

.contact-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    background: #111111;
    border-radius: 15px;
    padding: 25px;
    transform: translateY(-80px);
    /* align-content: center; */
    /* align-items: center; */
}

.contact h1 {
    position: absolute;
    left: -9999px;
    margin: 0; padding: 0;
}

.contact p {
    font-family: 'Microsoft Sans Serif';
    font-size: 1.3em;
    margin: 0; padding: 0;
    text-decoration: none;
}

.contact a {
    text-decoration: none;
    color: #00B8A0;
}

.contact ul {
    list-style-type: none;
    margin: 0; padding: 0;
    font-size: 1.1em;
}

.contact ul li {
    margin-top: 15px;
}