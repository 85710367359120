.home {
    overflow-y: auto; 
    position: absolute;
    left: 0; right: 0; 
    top: 80px; bottom: 0;
    padding-top: 20px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: #000;
}

.galaxy-bottom {
    display: none;
}

.home .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home .container .textWrap {
    display: flex;
    flex-direction: column;
    margin-right: 160px;
}

@media (max-width: 1024px) {
    .home .container {
        flex-direction: column;
        margin-top: 10px;
    }

    .home .container .textWrap {
        margin-right: 0;
    }

    .galaxy {
        display: none;
    }

    .galaxy-bottom {
        display: inline-block;
    }
}

.home .container .textWrap .titleWrap {
    align-self: center;
    flex: 1 1 auto;
}

.home .container .textWrap .titleWrap h1 {
    font-family: 'monaco', 'Microsoft Sans Serif';
    color: #00B8A0;
    font-weight: 200;
    text-align: center;
}

.home .container .textWrap .titleWrap p {
    font-family: 'Microsoft Sans Serif';
    color: #989898;
    text-align: center;
}

.home .container > img {
    align-self: flex-end;
}

.galaxy img {
    max-height: 80px;
    margin-top: 100px;
}

.galaxy-bottom img {
    max-height: 80px;
    margin-top: 30px;
}

.footer {
    position: absolute;
    bottom: 0; left: 0; right: 0;
}

.footer p {
    color: #FFF;
}

.promoSlideshow {}