.header {
    position: fixed; top: 0; left: 0; right: 0;
    display: grid;
    padding: 0 20px;
    grid-template-columns: 80px auto auto;
    height: 80px;
    background: rgb(0, 0, 0);
}

.header span {
    font-family: 'monaco', 'Microsoft Sans Serif';
    font-weight: 200;
}

.header > a {
    align-self: center;
    width: 60px;
}

.header > a img {
    width: 60px;
}

.header span {
    align-self: center;
    font-size: 16pt;
}

.header span a {
    color: #00B8A0;
    text-decoration: none;
}

.header nav {
    justify-self: end;
}

header ul {
    height: 100%;
    margin: 0; padding: 0;
}

header ul li {
    display: inline-block;
    height: 100%; line-height: 80px;
    margin-right: 20px;
}

header ul li a {
    display: block;
    color: #FFF;
    text-decoration: none;
}

header ul li a:hover {
    color: #c3c3c3;
}