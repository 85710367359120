.promoSlideshow {
    position: relative;
    overflow: hidden;
}

.containerImage {
}

@media (max-width: 1024px) {
    .containerImage {
        height: 300px;
    }

    .renderedImages {
        height: 200px;
    }

    .renderedImages img {
        height: 183px;
    }
}

.renderedImages {
    position: absolute; left: 3%; top: 19%;
    overflow: hidden;
}

.renderedImages ul li {
    display: inline-block;
    opacity: 0;
}

.renderedImages ul {
    display: flex;
}

.renderedImages ul li img {
}

.renderedImages ul {
    list-style-type: none;
    margin: 0; padding: 0;
}